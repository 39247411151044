@font-face {
    font-family: 'Obvia';
    src: url('./fonnts.com-Obvia_Thin.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Obvia';
    src: url('./fonnts.com-Obvia_Black.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Obvia';
    src: url('./fonnts.com-Obvia_Expanded_Medium.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}
